
import React from 'react';
import { NavLink, useRouteMatch, useLocation } from 'react-router-dom';
import ButtonHeaderSubMenu from './ButtonHeaderSubMenu';
import { dataRoutes } from '../../routes/dataRoutes';
import logo from './logo--SORANO.png';
import './stylesHeader.scss';
import { connect } from 'react-redux';
import { setIndex } from '../../routes/tour';


const ViewHeader = ({ setIndex}) => {    
    const getLinks = () => {
        const links = [];
        let currRoute;
        let currKeyIndex = 0;
        for (let i = 0; i < dataRoutes.length - 1; i++) { //skip home as it's used as the logo
            currRoute = dataRoutes[i];
            if (currRoute.title) { //if there's no title the link is assumed to be accessed elsewhere, outside the primary menu
                links.push(
                    <li className='header--nav__li' key={ currKeyIndex }>
                        {
                        currRoute.routes === undefined //whether it's a drop down or not
                        ? <NavLink className='header--nav__link' activeClassName='header--nav__link-selected' to={ currRoute.path }>{ currRoute.title }</NavLink>
                        : <ButtonHeaderSubMenu title={ currRoute.title } options={ currRoute.routes } />
                        }
                    </li>

                );
                currKeyIndex++;
            }
        }
        return links;
    }

    return (
        <header className={'header'}>
            <nav className='header--nav'>
                <ul>
                    <li className='header--nav__li'>
                        <button className='header--nav__link-logo' onClick={() => setIndex(0)}>
                            <img src={process.env.PUBLIC_URL + '/img/vr-app/ISPT04VRViewsDesign01Bar.jpg'} style={{ zIndex: 100}}/>
                        </button>
                    </li>
                    { getLinks() }
                </ul>
            </nav>
        </header>
    );


    
}

const mapStateToProps = (state) => ({
})
const mapDispatchToProps = (dispatch) => ({
    setIndex: (index) => dispatch(setIndex(index))
})


export default connect(mapStateToProps, mapDispatchToProps)(ViewHeader);