import React from 'react';
import { connect } from 'react-redux';
import { selectOverlaySlider } from '../overlay';
import '../../../shared/header/stylesHeader.scss';
import './stylesGallery.scss';


function ConnectViewGalleryYoutube ( { data, selectOverlaySlider } ) {

    const slideList = []; 
    const imageOptions = {maxHeight: 1080};
    let isDraggable = true;

    for (let i = 0; i < data.length; i++) { 
        slideList.push({
            src: data[i].href,
            caption: data[i].caption,
            options: imageOptions
        });
        // If any of these have multiple hrefs, make isDraggable false
        isDraggable = isDraggable && !Array.isArray(data[i].href);
    }

    const onImageSelected = (selectedIndex) => {
        selectOverlaySlider( { ...data[0], slideList: slideList, currentIndex: selectedIndex, sliderOptions: { touchMove: isDraggable } } );
    }

    return (
        <button className="header--nav__link" style={{ marginLeft: data[0].left, marginTop: data[0].top}} onClick={ () => onImageSelected(0) }>
            Car Park Video
        </button>
    );

}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    selectOverlaySlider: (data) => dispatch(selectOverlaySlider(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectViewGalleryYoutube);