import produce from 'immer';


// Actions
const UPDATE_ROTATION = 'QWR_APP/RESIDENCES/UPDATE_ROTATION';
const UPDATE_IS_ANIMATING = 'QWR_APP/RESIDENCES/UPDATE_IS_ANIMATING';
const SELECT_AREA = 'QWR_APP/RESIDENCES/SELECT_AREA';
const DESELECT_AREA = 'QWR_APP/RESIDENCES/DESELECT_AREA';
const SELECT_LEVEL_GROUP = 'QWR_APP/RESIDENCES/SELECT_LEVEL_GROUP';
const CLOSE_LEVEL_GROUP = 'QWR_APP/RESIDENCES/CLOSE_LEVEL_GROUP';
const SELECT_APARTMENT = 'QWR_APP/RESIDENCES/SELECT_APARTMENT';
const CLOSE_APARTMENT = 'QWR_APP/RESIDENCES/CLOSE_APARTMENT';
const TOGGLE_DAY_NIGHT = 'QWR_APP/RESIDENCES/TOGGLE_DAY_NIGHT';
const SET_TOWER = 'QWR_APP/RESIDENCES/SET_TOWER';

const defaultState = { 
    currRotation: 0, 
    isAnimating: false,
    area: '',
    isAreaActive: false,
    levelGroup: '',
    isLevelGroupActive: false,
    apartment: '',
    isApartmentActive: false,
    Day: 'Day',
}


// Reducer
export default function reducer (state = defaultState, action = {}) {
    switch (action.type) {

        case UPDATE_ROTATION :
            return produce(state, draftState => {
                draftState.currRotation = action.currRotation;
            });   
        case UPDATE_IS_ANIMATING :
            return produce(state, draftState => {
                draftState.isAnimating = action.isAnimating;
            });
        case SELECT_AREA :
            return produce(state, draftState => {
                draftState.isAreaActive = true;
                draftState.area = action.area;
            });
        case DESELECT_AREA :
            return produce(state, draftState => {
                draftState.isAreaActive = false;
            });
        case SELECT_LEVEL_GROUP :
            return produce(state, draftState => {
                draftState.isLevelGroupActive = true;
                draftState.levelGroup = action.levelGroup;
            });
        case CLOSE_LEVEL_GROUP :
            return produce(state, draftState => {
                draftState.isLevelGroupActive = false;
            });    
        case SELECT_APARTMENT :
            return produce(state, draftState => {
                draftState.isApartmentActive = true;
                draftState.apartment = action.apartment;
            });  
        case CLOSE_APARTMENT :
            return produce(state, draftState => {
                draftState.isApartmentActive = false;
            });     
        case TOGGLE_DAY_NIGHT:
            return produce(state, draftState => {
                draftState.Day = action.Day;
            });
        default :
            return state;
    }
}


// Action Creators
export const updateRotation = (currRotation) => ( {
    type: UPDATE_ROTATION,
    currRotation
} );

export const updateIsAnimating = (isAnimating) => ( {
    type: UPDATE_IS_ANIMATING,
    isAnimating
} );

export const selectArea = (area) => {
    return ( {
    type: SELECT_AREA,
    area
} )};

export const deselectArea = () => ( {
    type: DESELECT_AREA
} );

export const selectLevelGroup = ({ levelGroup, tower}) => ( {
    type: SELECT_LEVEL_GROUP,
    levelGroup,
} );

export const closeLevelGroup = () => ( {
    type: CLOSE_LEVEL_GROUP
} );

export const selectApartment = (apartment) => ( {
    type: SELECT_APARTMENT,
    apartment
} );

export const closeApartment = () => ( {
    type: CLOSE_APARTMENT
} );

export const toggleDayNight = (Day) => {
    return {
        type: TOGGLE_DAY_NIGHT,
        Day
    }
}
