import React, { useEffect, useState } from "react";
import MenuItem from "./MenuItem";
import { menuItemData } from './data';
import './menu.scss';
import MenuDuskSunset from "./MenuDuskSunset";

const MenuContainer = ({ onUrlChange }) => {
    const [streetSelected, setStreetSelected] = useState(0);
    const [duskSunsetSelected, setDuskSunsetSelected] = useState('sunset');

    useEffect(() => {
        const levelsData = menuItemData[streetSelected];
        onUrlChange(levelsData);
    }, [duskSunsetSelected, streetSelected]);
    return (
        <>
        <ul className="menu-group">
            { menuItemData.map((item, index) => (
                <MenuItem selected={streetSelected === index} index={index} item={item} key={item.menuItemLabel} className="menu-item" setSelected={(e) => setStreetSelected(e)}/>
            ))}

             {/*<MenuDuskSunset duskSunsetSelected={duskSunsetSelected} setDuskSunsetSelected={setDuskSunsetSelected} streetSelected={streetSelected} /> */}
        </ul>
        </>
    )
}

export default MenuContainer;