import React from 'react';
import { connect } from 'react-redux';
import { data } from './dataResidences';
import ConnectButtonResidencesHotspot from './ConnectButtonResidencesHotspot';
import ViewPhotoHotspots from '../../shared/photo-hotspot/ViewPhotoHotspots';

function ViewResidencesRotation ( { rotation, currRotation, area, isAreaActive, Day } ) {

  const display = rotation === currRotation ? 'block' : 'none'; //only if it's the currently active rotation
  const stringRotation = rotation < 10 ? ('0' + rotation) : rotation.toString(); //those below 10 have a starting 0
  const fileRef = `./img/residences/3D_Floorplans_${Day}/YPM001_3D_Floorplans_${Day.charAt(0).toUpperCase() + Day.slice(1)}_${stringRotation}.jpg`;
  
  const getHotspots = () => {
    const btnList = [];
    let currData;
    if (data.rotationHotspots[rotation] !== undefined) { //for those with hotspots, so only the rotations that have stops
        const dataList = data.rotationHotspots[rotation];
        for (let i = 0; i < dataList.length; i++) { 
          currData = dataList[i];
          currData.levelGroups = data.levelGroups[currData.title];
                    btnList.push(
            <ConnectButtonResidencesHotspot key={ i } data={ currData } />
          );
        }
    } 
    return btnList;
  }
  
  const getPhotoHotspots = () => {
    if (data.rotationPhotoHotspots[rotation] !== undefined) { //for those with hotspots, so only the rotations that have stops
      return <ViewPhotoHotspots data={ data.rotationPhotoHotspots[rotation] } />
    }
  }

  const getOverlay = () => {
    const overlays = data.rotationOverlays[rotation];
    const overlayImages = [];
    
   
    if ( !overlays || Object.keys(overlays) === 0 ) { return }

    const overlayKeys = Object.keys(overlays);


    for ( let idx=0; idx<overlayKeys.length; idx++ )
    {
      const key = overlayKeys[idx];
      const overlayArea = overlays[key];
      const display = ( isAreaActive && overlayArea && area === key ) ? 'block' : 'none';

      overlayImages.push(
        <img 
          key={ idx }
          className='residences--rotation-overlay' 
          src={overlayArea.src}
          alt={ area.title }
          style={ { display: display, top: overlayArea.top, left: overlayArea.left } }
          />
        );
    }

    return ( overlayImages );
  }

  return (
    <div style={ { display: display } } >
      <img src={ fileRef } className='shared--img-fill' alt='Rotation View' style={ { display: display } } />
      { getOverlay() }
      <div className='features--hotspots-container'>
      { getHotspots() }
      { getPhotoHotspots() }
      </div>
    </div>
  );
  
}


const mapStateToProps = (state) => ({
  currRotation: state.residences.currRotation,
  area: state.residences.area,
  isAreaActive: state.residences.isAreaActive,
  Day: state.residences.Day,
});

export default connect(mapStateToProps)(ViewResidencesRotation);