import React from 'react';
import './menu.scss';

const MenuItem = ({ item, index, className, selected, setSelected }) => {
    return (
            <li className={selected ? 'menu-item-selected' : className } onClick={() => setSelected(index)}>
                {item.menuItemLabel}
            </li>
        )
    };
export default MenuItem;