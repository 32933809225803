import produce from 'immer';


// Actions
const SET_INDEX = 'QWR_APP/TOUR/SET_INDEX';


// Reducer
export default function reducer (state = { index: 0 }, action = {}) {
    switch (action.type) {
        case SET_INDEX :
            return produce(state, draftState => {
                draftState.index = action.index;
            });       
        default :
            return state;
    }
}


// Action Creators
export const setIndex = (index) => ( {
    type: SET_INDEX,
    index
} );