export const menuItemData = [

  {
    menuItemLabel: 'High Rise',
    type: 'standalone',
    data: process.env.PUBLIC_URL + '/vr-app/highrise/index.htm',
  },

  {
    menuItemLabel: 'Mid rise',
    type: 'standalone',
    data: process.env.PUBLIC_URL + '/vr-app/midrise/index.htm',
  },
  {
    menuItemLabel: 'Low rise',
    type: 'standalone',
    data: process.env.PUBLIC_URL + '/vr-app/lowrise/index.htm',
  },
];