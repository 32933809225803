export const youtube = [{title: 'CAR PARK VIDEO', type: 'video', left: '1474px', top: '720px', src: 'https://www.youtube.com/watch?v=SQrOHlheHHM', href: 'https://www.youtube.com/watch?v=SQrOHlheHHM'}];

//LOCATION
export const locationlifestyle1 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/location-lifestyle/bkg--location-lifestyle-1.jpg', href: '/img/location-lifestyle/bkg--location-lifestyle-1.jpg' },
]
export const locationlifestyle2 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/location-lifestyle/bkg--location-lifestyle-2.jpg', href: '/img/location-lifestyle/bkg--location-lifestyle-2.jpg' },
]

//INTERIORS
export const interiorsImages1 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-images/bkg--interiors-images-1.jpg'},
]
export const interiorsImages2 = [
	{ title: '2', left: '0px', top: '0px', src: '/img/interiors-images/bkg--interiors-images-2.jpg'},
]
export const interiorsImages3 = [
	{ title: '3', left: '0px', top: '0px', src: '/img/interiors-images/bkg--interiors-images-3.jpg'},
]
export const interiorsImages4 = [
	{ title: '4', left: '0px', top: '0px', src: '/img/interiors-images/bkg--interiors-images-4.jpg'},
]
export const interiorsImages5 = [
	{ title: '5', left: '0px', top: '0px', src: '/img/interiors-images/bkg--interiors-images-5.jpg'},
]
export const interiorsImages6 = [
	{ title: '6', left: '0px', top: '0px', src: '/img/interiors-images/bkg--interiors-images-6.jpg'},
]
export const interiorsImages7 = [
	{ title: '7', left: '0px', top: '0px', src: '/img/interiors-images/bkg--interiors-images-7.jpg'},
]
export const interiorsImages8 = [
	{ title: '8', left: '0px', top: '0px', src: '/img/interiors-images/bkg--interiors-images-8.jpg'},
]
export const interiorsImages9 = [
	{ title: '8', left: '0px', top: '0px', src: '/img/interiors-images/bkg--interiors-images-9.jpg'},
]


export const interiorsFinishes1 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-finishes/bkg--interiors-finishes-1.jpg'},
]
export const interiorsFinishes2 = [
	{ title: '2', left: '0px', top: '0px', src: '/img/interiors-finishes/bkg--interiors-finishes-2.jpg'},
]
export const interiorsFinishes3 = [
	{ title: '3', left: '0px', top: '0px', src: '/img/interiors-finishes/bkg--interiors-finishes-3.jpg'},
]
export const interiorsFinishes4 = [
	{ title: '4', left: '0px', top: '0px', src: '/img/interiors-finishes/bkg--interiors-finishes-4.jpg'},
]
export const interiorsFinishes5 = [
	{ title: '5', left: '0px', top: '0px', src: '/img/interiors-finishes/bkg--interiors-finishes-5.jpg'},
]
export const interiorsFinishes6 = [
	{ title: '6', left: '0px', top: '0px', src: '/img/interiors-finishes/bkg--interiors-finishes-6.jpg'},
]
export const interiorsFinishes7 = [
	{ title: '7', left: '0px', top: '0px', src: '/img/interiors-finishes/bkg--interiors-finishes-7.jpg'},
]


export const interiorsDetail1 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-1.jpg'},
]
export const interiorsDetail2 = [
	{ title: '2', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-2.jpg'},
]
export const interiorsDetail3 = [
	{ title: '3', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-3.jpg'},
]
export const interiorsDetail4 = [
	{ title: '4', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-4.jpg'},
]
export const interiorsDetail5 = [
	{ title: '5', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-5.jpg'},
]
export const interiorsDetail6 = [
	{ title: '6', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-6.jpg'},
]
export const interiorsDetail7 = [
	{ title: '7', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-7.jpg'},
]
export const interiorsDetail8 = [
	{ title: '8', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-8.jpg'},
]
export const interiorsDetail9 = [
	{ title: '9', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-9.jpg'},
]
export const interiorsDetail10 = [
	{ title: '10', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-10.jpg'},
]
export const interiorsDetail11 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-11.jpg'},
]
export const interiorsDetail12 = [
	{ title: '2', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-12.jpg'},
]
export const interiorsDetail13 = [
	{ title: '3', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-13.jpg'},
]
export const interiorsDetail14 = [
	{ title: '4', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-14.jpg'},
]
export const interiorsDetail15 = [
	{ title: '5', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-15.jpg'},
]
export const interiorsDetail16 = [
	{ title: '6', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-16.jpg'},
]
export const interiorsDetail17 = [
	{ title: '7', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-17.jpg'},
]
export const interiorsDetail18 = [
	{ title: '8', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-18.jpg'},
]
export const interiorsDetail19 = [
	{ title: '9', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-19.jpg'},
]
export const interiorsDetail20 = [
	{ title: '10', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-20.jpg'},
]
export const interiorsDetail21 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-21.jpg'},
]
export const interiorsDetail22 = [
	{ title: '2', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-22.jpg'},
]
export const interiorsDetail23 = [
	{ title: '3', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-23.jpg'},
]
export const interiorsDetail24 = [
	{ title: '4', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-24.jpg'},
]
export const interiorsDetail25 = [
	{ title: '5', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-25.jpg'},
]
export const interiorsDetail26 = [
	{ title: '6', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-26.jpg'},
]
export const interiorsDetail27 = [
	{ title: '7', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-27.jpg'},
]
export const interiorsDetail28 = [
	{ title: '8', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-28.jpg'},
]
export const interiorsDetail29 = [
	{ title: '9', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-29.jpg'},
]
export const interiorsDetail30 = [
	{ title: '10', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-30.jpg'},
]
export const interiorsDetail31 = [
	{ title: '9', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-31.jpg'},
]
export const interiorsDetail32 = [
	{ title: '10', left: '0px', top: '0px', src: '/img/interiors-detail/bkg--interiors-detail-32.jpg'},
]

//TEAMS
export const team1 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/team/bkg--team-1.jpg'},
]
export const team2 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/team/bkg--team-2.jpg'},
]
export const team3 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/team/bkg--team-3.jpg'},
]
export const team4 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/team/bkg--team-4.jpg'},
]
export const team5 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/team/bkg--team-5.jpg'},
]

//DESIGN
export const design1 = [
	{ title: '1', left: '0px', top: '0px', src: '/img/design/bkg--design-1.jpg' },
]
export const design2 = [
	{ title: '2', left: '0px', top: '0px', src: '/img/design/bkg--design-2.jpg' },
]
export const design3 = [
	{ title: '3', left: '0px', top: '0px', src: '/img/design/bkg--design-3.jpg' },
]
export const design4 = [
	{ title: '4', left: '0px', top: '0px', src: '/img/design/bkg--design-4.jpg' },
]
export const design5 = [
	{ title: '5', left: '0px', top: '0px', src: '/img/design/bkg--design-5.jpg' },
]
export const design6 = [
	{ title: '6', left: '0px', top: '0px', src: '/img/design/bkg--design-6.jpg' },
]
export const design7 = [
	{ title: '7', left: '0px', top: '0px', src: '/img/design/bkg--design-7.jpg' },
]
export const design8 = [
	{ title: '8', left: '0px', top: '0px', src: '/img/design/bkg--design-8.jpg' },
]
export const design9 = [
	{ title: '9', left: '0px', top: '0px', src: '/img/design/bkg--design-9.jpg' },
]

//GALLERY
export const building = [
    { title: '1', left: '68px', top: '17px', src: '/img/gallery-building/gallery-building-1-superman-city-tmb.jpg', href: '/img/gallery-building/gallery-building-1-superman-city.jpg' },
	{ title: '2', left: '518px', top: '17px', src: '/img/gallery-building/gallery-building-2-partial-screens-tmb.jpg', href: '/img/gallery-building/gallery-building-2-partial-screens.jpg' },
	{ title: '3', left: '967px', top: '17px', src: '/img/gallery-building/gallery-building-3-exterior-vignette-tmb.jpg', href: '/img/gallery-building/gallery-building-3-exterior-vignette.jpg' },
	{ title: '4', left: '1416px', top: '17px', src: '/img/gallery-building/gallery-building-4-typical-balcony-tmb.jpg', href: '/img/gallery-building/gallery-building-4-typical-balcony.jpg' },
	{ title: '5', left: '68px', top: '338px', src: '/img/gallery-building/gallery-building-5-typical-living-tmb.jpg', href: '/img/gallery-building/gallery-building-5-typical-living.jpg' },
	{ title: '6', left: '518px', top: '338px', src: '/img/gallery-building/gallery-building-6-typical-kitchen-tmb.jpg', href: '/img/gallery-building/gallery-building-6-typical-kitchen.jpg' },
	{ title: '7', left: '967px', top: '338px', src: '/img/gallery-building/gallery-building-7-sectional-tmb.jpg', href: '/img/gallery-building/gallery-building-7-sectional.jpg' },
	{ title: '8', left: '1416px', top: '338px', src: '/img/gallery-building/gallery-building-8-typical-dining-tmb.jpg', href: '/img/gallery-building/gallery-building-8-typical-dining.jpg' },
	{ title: '9', left: '68px', top: '659px', src: '/img/gallery-building/gallery-building-9-typical-ensuite-tmb.jpg', href: '/img/gallery-building/gallery-building-9-typical-ensuite.jpg'  },
	{ title: '10', left: '518px', top: '659px', src: '/img/gallery-building/gallery-building-10-typical-ensuite-vignette-tmb.jpg', href: '/img/gallery-building/gallery-building-10-typical-ensuite-vignette.jpg'  },
	{ title: '11', left: '967px', top: '659px', src: '/img/gallery-building/gallery-building-11-typical-bedroom-tmb.jpg', href: '/img/gallery-building/gallery-building-11-typical-bedroom.jpg'   },
	{ title: '12', left: '1416px', top: '659px',  src: '/img/gallery-building/gallery-building-12-typical-WIR-tmb.jpg', href: '/img/gallery-building/gallery-building-12-typical-WIR.jpg'  },
];

export const amenities = [
	{ title: '1', left: '68px', top: '17px', src: '/img/gallery-amenities/gallery-amenities-1-lobby-tmb.jpg', href: '/img/gallery-amenities/gallery-amenities-1-lobby.jpg' },
	{ title: '2', left: '518px', top: '17px', src: '/img/gallery-amenities/gallery-amenities-2-communal-dining-tmb.jpg', href: '/img/gallery-amenities/gallery-amenities-2-communal-dining.jpg' },
	{ title: '3', left: '967px', top: '17px', src: '/img/gallery-amenities/gallery-amenities-3-pool-tmb.jpg', href: '/img/gallery-amenities/gallery-amenities-3-pool.jpg' },
	{ title: '4', left: '1416px', top: '17px', src: '/img/gallery-amenities/gallery-amenities-4-gym-tmb.jpg', href: '/img/gallery-amenities/gallery-amenities-4-gym.jpg' },
	{ title: '5', left: '68px', top: '338px', src: '/img/gallery-amenities/gallery-amenities-5-sauna-tmb.jpg', href: '/img/gallery-amenities/gallery-amenities-5-sauna.jpg' },
]