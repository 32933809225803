
export const data = [{
    id: 0,
    levels: [
        { 
            title: 'Level 11', 
            src: './img/amenities/bkg--T1&T2-amenities-L11.jpg',
            index: 0,
            hotSpots: [
                { title: 'L11-garden-lounge', left: '780px', top: '855px', align: 'top-right', src: './img/amenities/T1&T2-amenities-L11-garden-lounge-tmb.jpg', href: './img/amenities/T1&T2-amenities-L11-garden-lounge-enlarge.jpg' },
                { title: 'L11-heated-pool', left: '800px', top: '330px', align: 'top-right', src: './img/amenities/T1&T2-amenities-L11-heated-pool-tmb.jpg', href: './img/amenities/T1&T2-amenities-L11-heated-pool-enlarge.jpg' },
                { title: 'L11-mahjong-room', left: '970px', top: '850px', align: 'top-right', src: './img/amenities/T1&T2-amenities-L11-mahjong-room-tmb.jpg', href: './img/amenities/T1&T2-amenities-L11-mahjong-room-enlarge.jpg' },
                { title: 'L11-welness-centre', left: '1250px', top: '295px', align: 'top-right', src: './img/amenities/T1&T2-amenities-L11-welness-centre-tmb.jpg', href: './img/amenities/T1&T2-amenities-L11-welness-centre-enlarge.jpg' },
            ]
        },
        { 
            title: 'Level 7', 
            src: './img/amenities/bkg--T1&T2-amenities-L7.jpg',
            index: 1,
            hotSpots: [
                { title: 'L7-boardroom', left: '1010px', top: '320px', align: 'top-right', src: './img/amenities/T1&T2-amenities-L7-boardroom-tmb.jpg', href: './img/amenities/T1&T2-amenities-L7-boardroom-enlarge.jpg' },
                { title: 'L7-library', left: '800px', top: '300px', align: 'top-right', src: './img/amenities/T1&T2-amenities-L7-library-tmb.jpg', href: './img/amenities/T1&T2-amenities-L7-library-enlarge.jpg' },
            ]
        },
        { 
            title: 'Level 6', 
            src: './img/amenities/bkg--T1&T2-amenities-L6.jpg',
            index: 2,
            hotSpots: [
                { title: 'L6-breakout-seating-fireplace', left: '890px', top: '630px', align: 'top-right', src: './img/amenities/T1&T2-amenities-L6-breakout-seating-fireplace-tmb.jpg', href: './img/amenities/T1&T2-amenities-L6-breakout-seating-fireplace-enlarge.jpg' },
                { title: 'L6-feature-staircase', left: '1045px', top: '675px', align: 'top-right', src: './img/amenities/T1&T2-amenities-L6-feature-staircase-tmb.jpg', href: './img/amenities/T1&T2-amenities-L6-feature-staircase-enlarge.jpg' },
                { title: 'L6-heated-lap-pool', left: '900px', top: '480px', align: 'top-right', src: './img/amenities/T1&T2-amenities-L6-heated-lap-pool-tmb.jpg', href: './img/amenities/T1&T2-amenities-L6-heated-lap-pool-enlarge.jpg' },
                { title: 'L6-private-cinema', left: '940px', top: '760px', align: 'top-right', src: './img/amenities/T1&T2-amenities-L6-private-cinema-tmb.jpg', href: './img/amenities/T1&T2-amenities-L6-private-cinema-enlarge.jpg' },
                { title: 'L6-private-dining-room', left: '1270px', top: '790px', align: 'top-right', src: './img/amenities/T1&T2-amenities-L6-private-dining-room-tmb.jpg', href: './img/amenities/T1&T2-amenities-L6-private-dining-room-enlarge.jpg' },
                { title: 'L6-private-karaoke-room', left: '860px', top: '800px', align: 'top-right', src: './img/amenities/T1&T2-amenities-L6-private-karaoke-room-tmb.jpg', href: './img/amenities/T1&T2-amenities-L6-private-karaoke-room-enlarge.jpg' },
            ]        
            },
        { 
            title: 'Ground', 
            src: './img/amenities/bkg--T1&T2-amenities-G.jpg',
            index: 3,
            hotSpots: [
                { title: 'G-ritz-foyer', left: '970px', top: '870px', align: 'top-right', src: './img/amenities/T1&T2-amenities-G-ritz-foyer-tmb.jpg', href: './img/amenities/T1&T2-amenities-G-ritz-foyer-enlarge.jpg' },
                { title: 'G-ritz-port-cochere', left: '1090px', top: '770px', align: 'top-right', src: './img/amenities/T1&T2-amenities-G-ritz-port-cochere-tmb.jpg', href: './img/amenities/T1&T2-amenities-G-ritz-port-cochere-enlarge.jpg' },
                { title: 'G-tower-1-lobby', left: '830px', top: '700px', align: 'top-right', src: './img/amenities/T1&T2-amenities-G-tower-1-lobby-tmb.jpg', href: './img/amenities/T1&T2-amenities-G-tower-1-lobby-enlarge.jpg' },
                { title: 'G-tower-2-lobby', left: '1040px', top: '410px', align: 'top-right', src: './img/amenities/T1&T2-amenities-G-tower-2-lobby-tmb.jpg', href: './img/amenities/T1&T2-amenities-G-tower-2-lobby-enlarge.jpg' },
            ]
        },
    ]
},
{
    id: 1,
    levels: [
        { 
            title: 'Level 10', 
            src: './img/amenities/bkg--T3&T4-amenities-L10.jpg',
            index: 0,
            hotSpots: [
                { title: 'L10-movement-studio', left: '1065px', top: '460px', align: 'bot-right', src: './img/amenities/T3&T4-amenities-L10-movement-studio-tmb.jpg', href: './img/amenities/T3&T4-amenities-L10-movement-studio-enlarge.jpg' },
                { title: 'L10-plunge-pool-courtyard', left: '1065px', top: '640px', align: 'bot-right', src: './img/amenities/T3&T4-amenities-L10-plunge-pool-courtyard-tmb.jpg', href: './img/amenities/T3&T4-amenities-L10-plunge-pool-courtyard-enlarge.jpg' },
                { title: 'L10-private-lounge', left: '740px', top: '330px', align: 'bot-right', src: './img/amenities/T3&T4-amenities-L10-private-lounge-tmb.jpg', href: './img/amenities/T3&T4-amenities-L10-private-lounge-enlarge.jpg' },
                { title: 'L10-screening-room', left: '780px', top: '260px', align: 'bot-right', src: './img/amenities/T3&T4-amenities-L10-screening-room-tmb.jpg', href: './img/amenities/T3&T4-amenities-L10-screening-room-enlarge.jpg' },
                { title: 'L10-vip-wine-tasting-room', left: '840px', top: '185px', align: 'bot-right', src: './img/amenities/T3&T4-amenities-L10-vip-wine-tasting-room-tmb.jpg', href: './img/amenities/T3&T4-amenities-L10-vip-wine-tasting-room-enlarge.jpg' },

            ]
        },
        { 
            title: 'Level 9', 
            src: './img/amenities/bkg--T3&T4-amenities-L9.jpg',
            index: 1,
            hotSpots: [
                { title: 'L9-billiards', left: '1005px', top: '600px', align: 'top-right', src: './img/amenities/T3&T4-amenities-L9-billiards-tmb.jpg', href: './img/amenities/T3&T4-amenities-L9-billiards-enlarge.jpg' },
                { title: 'L9-cinema', left: '1010px', top: '720px', align: 'top-right', src: './img/amenities/T3&T4-amenities-L9-cinema-tmb.jpg', href: './img/amenities/T3&T4-amenities-L9-cinema-enlarge.jpg' },
                { title: 'L9-function-room', left: '670px', top: '845px', align: 'top-right', src: './img/amenities/T3&T4-amenities-L9-function-room-tmb.jpg', href: './img/amenities/T3&T4-amenities-L9-function-room-enlarge.jpg' },
                { title: 'L9-games-room', left: '1030px', top: '665px', align: 'top-right', src: './img/amenities/T3&T4-amenities-L9-games-room-tmb.jpg', href: './img/amenities/T3&T4-amenities-L9-games-room-enlarge.jpg' },
                { title: 'L9-garden-lounge', left: '1015px', top: '350px', align: 'top-right', src: './img/amenities/T3&T4-amenities-L9-garden-lounge-tmb.jpg', href: './img/amenities/T3&T4-amenities-L9-garden-lounge-enlarge.jpg' },
                { title: 'L9-gymnasium', left: '820px', top: '280px', align: 'top-right', src: './img/amenities/T3&T4-amenities-L9-gymnasium-tmb.jpg', href: './img/amenities/T3&T4-amenities-L9-gymnasium-enlarge.jpg' },
                { title: 'L9-karaoke', left: '1050px', top: '610px', align: 'top-right', src: './img/amenities/T3&T4-amenities-L9-karaoke-tmb.jpg', href: './img/amenities/T3&T4-amenities-L9-karaoke-enlarge.jpg' },
                { title: 'L9-lap-pool', left: '770px', top: '580px', align: 'top-right', src: './img/amenities/T3&T4-amenities-L9-lap-pool-tmb.jpg', href: './img/amenities/T3&T4-amenities-L9-lap-pool-enlarge.jpg' },
                { title: 'L9-private-dining-room', left: '750px', top: '890px', align: 'top-right', src: './img/amenities/T3&T4-amenities-L9-private-dining-room-tmb.jpg', href: './img/amenities/T3&T4-amenities-L9-private-dining-room-enlarge.jpg' },
                { title: 'L9-residential-lounge', left: '1025px', top: '435px', align: 'top-right', src: './img/amenities/T3&T4-amenities-L9-residential-lounge-tmb.jpg', href: './img/amenities/T3&T4-amenities-L9-residential-lounge-enlarge.jpg' },
                { title: 'L9-study-reading-room', left: '1000px', top: '880px', align: 'top-right', src: './img/amenities/T3&T4-amenities-L9-study-reading-room-tmb.jpg', href: './img/amenities/T3&T4-amenities-L9-study-reading-room-enlarge.jpg' },
                { title: 'L9-vip-styling-room', left: '1000px', top: '790px', align: 'top-right', src: './img/amenities/T3&T4-amenities-L9-vip-styling-room-tmb.jpg', href: './img/amenities/T3&T4-amenities-L9-vip-styling-room-enlarge.jpg' },
                { title: 'L9-virtual-golf', left: '1030px', top: '552px', align: 'top-right', src: './img/amenities/T3&T4-amenities-L9-virtual-golf-tmb.jpg', href: './img/amenities/T3&T4-amenities-L9-virtual-golf-enlarge.jpg' },
                { title: 'L9-wine-tasting-room', left: '910px', top: '880px', align: 'top-right', src: './img/amenities/T3&T4-amenities-L9-wine-tasting-room-tmb.jpg', href: './img/amenities/T3&T4-amenities-L9-wine-tasting-room-enlarge.jpg' },

            ]
        },
        { 
            title: 'Ground', 
            src: './img/amenities/bkg--T3&T4-amenities-G.jpg',
            index: 2,
            hotSpots: [
                { title: 'G-dorsett-reception', left: '1000px', top: '420px', align: 'top-right', src: './img/amenities/T3&T4-amenities-G-dorsett-reception-tmb.jpg', href: './img/amenities/T3&T4-amenities-G-dorsett-reception-enlarge.jpg' },
                { title: 'G-tower-3-lobby', left: '795px', top: '360px', align: 'top-right', src: './img/amenities/T3&T4-amenities-G-tower-3-lobby-tmb.jpg', href: './img/amenities/T3&T4-amenities-G-tower-3-lobby-enlarge.jpg' },
                { title: 'G-tower-4-lobby', left: '735px', top: '750px', align: 'top-right', src: './img/amenities/T3&T4-amenities-G-tower-4-lobby-tmb.jpg', href: './img/amenities/T3&T4-amenities-G-tower-4-lobby-enlarge.jpg' },
            ]
        },
    ]
}];