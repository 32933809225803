import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { closeLevelGroup } from './';
import { data } from './dataResidences';

import ConnectButtonResidencesApartment from './ConnectButtonResidencesApartment';
import ConnectButtonPhotoHotspot from '../../shared/photo-hotspot/ConnectButtonPhotoHotspot';
import { youtube } from '../gallery/data/gallery';
import ConnectViewGalleryYoutube from '../../app/components/gallery/ConnectViewGalleryYoutube';
import LevelGroupMenu from './LevelGroupMenu';
import LevelGroupMenuItem from './LevelGroupMenuItem';


function ViewResidencesLevelGroup ( { residences, closeLevelGroup } ) {
  const [levelGroup, setLevelGroup] = useState();

  useEffect(() => {
    setLevelGroup(residences.levelGroup);
  }, [residences.levelGroup]);
  const getApartments = () => {   
    const btnList = [];
    if (data.levelGroupApartments[levelGroup] !== undefined) {
        const dataList = data.levelGroupApartments[levelGroup]; 
        for (let i = 0; i < dataList.length; i++) {     
          if (dataList[i].type && dataList[i].type === 'photo')
            {
              btnList.push(
                <ConnectButtonPhotoHotspot key={ i } data={ dataList[i] } onSelected={ () => {} } />
              );
            } else 
            {
              btnList.push(
                <ConnectButtonResidencesApartment key={ i } data={ dataList[i] }  />
              );
            }
        }
    } 
    return btnList;
  }

/*const renderMenu = () => {
    switch (residences.levelGroup) {
      case "Basement":
        return <LevelGroupMenu><LevelGroupMenuItem setLevelGroup={setLevelGroup} label="Basement" target="Basement" /></LevelGroupMenu>
      case "Ground":
        return <LevelGroupMenu><LevelGroupMenuItem setLevelGroup={setLevelGroup} label="Ground" target="Ground" /></LevelGroupMenu>
      case "Level 1":
        return <LevelGroupMenu><LevelGroupMenuItem setLevelGroup={setLevelGroup} label="Level 1" target="Level 1" /></LevelGroupMenu>
      case "Level 2":
        return <LevelGroupMenu><LevelGroupMenuItem setLevelGroup={setLevelGroup} label="Level 2" target="Level 2" /></LevelGroupMenu>
      case "Level 3":
        return <LevelGroupMenu><LevelGroupMenuItem setLevelGroup={setLevelGroup} label="Level 3" target="Level 3" /></LevelGroupMenu>
      case "Level 4":
        return <LevelGroupMenu><LevelGroupMenuItem setLevelGroup={setLevelGroup} label="Level 4" target="Level 4" /></LevelGroupMenu>
      case "Level 5":
        return <LevelGroupMenu><LevelGroupMenuItem setLevelGroup={setLevelGroup} label="Level 5" target="Level 5" /></LevelGroupMenu>     
      case "Level 6":
        return <LevelGroupMenu><LevelGroupMenuItem setLevelGroup={setLevelGroup} label="Level 6" target="Level 6" /></LevelGroupMenu>
      case "Penthouse":
        return <LevelGroupMenu><LevelGroupMenuItem setLevelGroup={setLevelGroup} label="Penthouse" target="Penthouse" /></LevelGroupMenu>  
      //case "Level 1":
       // return <LevelGroupMenu><LevelGroupMenuItem setLevelGroup={setLevelGroup} label="Level 1" target="Level 1" selected={levelGroup === "Level 1"}/><LevelGroupMenuItem setLevelGroup={setLevelGroup} label="Level 2" target="Level 2"  selected={levelGroup === "Level 2"}/></LevelGroupMenu>
      }
  }
  
  return (
      <CSSTransition className='residences--level-group' in={ residences.isLevelGroupActive } timeout={ 300 } classNames='fade' unmountOnExit>
          <div>
              <img className='shared--img-fill' src={ './img/floor-plates/YPM002-Floorplates_' + levelGroup + '.jpg' } width='1920' height='1080' alt='residences level' />
              <div className='residences--apartment-btns'>
                { getApartments() }
                { renderMenu() }
                { residences.levelGroup === "Ground" && (
                  <ConnectViewGalleryYoutube data={youtube} />
                )}
                
              </div>
                  <button className='residences--level-group__close-btn' onClick={ () => { closeLevelGroup()}} />
          </div>
      </CSSTransition> 
  );

  return (
    <CSSTransition className='residences--level-group' in={ residences.isLevelGroupActive } timeout={ 300 } classNames='fade' unmountOnExit>
        <div>
            <img className='shared--img-fill' src={ './img/floor-plates/YPM001-Floorplates_' + levelGroup + '.jpg' } width='1920' height='980' alt='residences level' />
            <div className='residences--apartment-btns'>
              { getApartments() }
              { residences.levelGroup === "Basement" && (
                <ConnectViewGalleryYoutube data={youtube} />
              )}
              
            </div>
                <button className='residences--level-group__close-btn' onClick={ () => { closeLevelGroup()}} />
        </div>
    </CSSTransition> 
);*/

return (
  <CSSTransition className='residences--level-group' in={ residences.isLevelGroupActive } timeout={ 300 } classNames='fade' unmountOnExit>
      <div>
          <img className='shared--img-fill' src={ './img/floor-plates/YPM001-Floorplates_' + levelGroup + '.jpg' } width='1920' height='980' alt='residences level' />
          <div className='residences--apartment-btns'>
            { getApartments() }
            { residences.levelGroup === "Basement(removethispart)" && (
              <ConnectViewGalleryYoutube data={youtube} />
            )}
            
          </div>
              <button className='residences--level-group__close-btn' onClick={ () => { closeLevelGroup()}} />
      </div>
  </CSSTransition> 
);

}


const mapStateToProps = (state) => ({
  residences: state.residences,
  tower: state.residences.tower,
})

const mapDispatchToProps = (dispatch) => ({
  closeLevelGroup: () => dispatch(closeLevelGroup())
})


export default connect(mapStateToProps, mapDispatchToProps)(ViewResidencesLevelGroup)