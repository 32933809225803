import produce from "immer";


// Actions
const SELECT_ENLARGED = 'QWR_APP/ENLARGED/SELECT_ENLARGED';
const CLOSE_ENLARGED = 'QWR_APP/ENLARGED/CLOSE_ENLARGED';


// Reducer
export default function reducer (state = { isActive: false, data: { srcList: [], currSrcIndex: 0 } }, action = {}) {
    switch (action.type) {
        case SELECT_ENLARGED :
            return produce(state, draftState => {
                draftState.isActive = true;
                draftState.data = action.data;
            });  
        case CLOSE_ENLARGED :
            return produce(state, draftState => {
                draftState.isActive = false;
            });      
        default :
            return state;
    }
}


// Action Creators
export const selectEnlarged = (data) => ( {
    type: SELECT_ENLARGED,
    data
} );

export const closeEnlarged = () => ( {
    type: CLOSE_ENLARGED
} );
