import React, { useEffect } from 'react';
import { setMounted } from '.';
import { connect } from 'react-redux';



const View3dAPP = ({ url }) => {
      console.log('URLURL', url);
        return(
            <div>

                <iframe src={url.data} frameBorder={0} style={{ position: 'absolute', overflow: 'hidden', height: '1084px', width: '100%', top: '100px'}} height="1084px" width="100%" title="3DView" />
             </div>
        );
  }






export default View3dAPP;
//Photo by Sharon McCutcheon on Unsplash
//https://unsplash.com/photos/-vgLTTWPesg
