import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { selectLevelGroup, selectArea, deselectArea } from '.';
import { gsap } from 'gsap/all';
import LevelGroupItem from './LevelGroupItem';


function ButtonResidencesHotspot ( { data, selectLevelGroup, selectArea, deselectArea } ) {

  const containerRef = useRef(null);


  const getSubMenu = () => {
    const optionsList = [data.rotation];
    const levelGroups = data.levelGroups;

    for (let i = 0; i < levelGroups.length; i++) {
      optionsList.push(
        <LevelGroupItem item={levelGroups[i]} key={i} selectLevelGroup={selectLevelGroup} selectArea={selectArea} deselectArea={deselectArea} />
      );
    }
    return optionsList;
  }

  return (
    <div className='residences--hotspot' ref={ containerRef } style={ { left: data.left, top: data.top } }>
        { getSubMenu() }
    </div>
  );

}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  selectLevelGroup: (levelGroup) => dispatch(selectLevelGroup(levelGroup)),
  selectArea: (area) => dispatch(selectArea(area)),
  deselectArea: () => dispatch(deselectArea())
});

export default connect(mapStateToProps, mapDispatchToProps)(ButtonResidencesHotspot);