import React from "react";
import AccordianContainer from "../accordian/AccordianContainer";
import './sideMenu.scss';
import MenuContainer from "../menu/MenuContainer";

const SideMenu = ({onUrlChange}) => {
    return (
        <div>
            <MenuContainer onUrlChange={onUrlChange} />
        </div>
    )
}

export default SideMenu;