import React from 'react';
import { selectApartment } from './';
import { connect } from 'react-redux';
import ApartmentDropDown from './ApartmentDropDown';


function ButtonResidencesApartment ( { data, selectApartment } ) {
  return (
      <ApartmentDropDown data={data} selectApartment={selectApartment} />
  );
}



const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  selectApartment: (apartment) => dispatch(selectApartment(apartment))
});

export default connect(mapStateToProps, mapDispatchToProps)(ButtonResidencesApartment);