const MENU_0 = 'Menu 0';
const MENU_18 = 'Menu 18';
const MENU_36 = 'Menu 36';
const MENU_54 = 'Menu 54';

export const data = {
    rotationHotspots: {
        0: [
            { title: MENU_0, left: '1300px', top: '185px' },
        ],
        18: [
            { title: MENU_18, left: '1300px', top: '185px' },
        ],
        36: [
            { title: MENU_36, left: '1300px', top: '185px' },
        ],
        54: [
            { title: MENU_54, left: '1300px', top: '185px' },
        ]
    },
    rotationPhotoHotspots: {
        0: [
            { left: '1260px', top: '780px', type: 'photo', align: 'top-right', src: './img/residences/building-screens-tmb.jpg', href: './img/gallery-building/gallery-building-2-partial-screens.jpg' },
            { left: '1220px', top: '300px', type: 'photo', src: './img/residences/building-superman-tmb.jpg', href: './img/gallery-building/gallery-building-1-superman-city.jpg' },
        ],
        18: [
            { left: '1320px', top: '530px', type: 'photo', src: './img/residences/building-screens-tmb.jpg', href: './img/gallery-building/gallery-building-2-partial-screens.jpg' },
            { left: '600px', top: '280px', type: 'photo', align: 'top-right', src: './img/residences/building-superman-tmb.jpg', href: './img/gallery-building/gallery-building-1-superman-city.jpg' },
        ],
        36: [
            { left: '600px', top: '490px', type: 'photo', src: './img/residences/building-screens-tmb.jpg', href: './img/gallery-building/gallery-building-2-partial-screens.jpg' },
            { left: '760px', top: '400px', type: 'photo', align: 'top-right', src: './img/residences/building-superman-tmb.jpg', href: './img/gallery-building/gallery-building-1-superman-city.jpg' },
        ],
        54: [
            { left: '620px', top: '840px', type: 'photo', align: 'top-right', src: './img/residences/building-screens-tmb.jpg', href: './img/gallery-building/gallery-building-2-partial-screens.jpg' },
            { left: '1280px', top: '300px', type: 'photo', align: 'top-right', src: './img/residences/building-superman-tmb.jpg', href: './img/gallery-building/gallery-building-1-superman-city.jpg' },
        ]
    },
    levelGroups: {
        'Menu 0': [
            { level: 'Penthouse', target: 'Penthouse' },
            { level: 'Level 6', target: 'Level 6' },
            { level: 'Level 5', target: 'Level 5' },
            { level: 'Level 4', target: 'Level 4' },
            { level: 'Level 3', target: 'Level 3' },
            { level: 'Level 2', target: 'Level 2' },
            { level: 'Level 1', target: 'Level 1' },
            { level: 'Ground', target: 'Ground'},
            { level: 'Basement', target: 'Basement'},
            //{ level: 'Basement', target: 'Basement', link: { label: 'Car Park Video', url: 'https://www.youtube.com/embed?v=SQrOHlheHHM' } },

        ],   
        'Menu 18': [
            { level: 'Penthouse', target: 'Penthouse' },
            { level: 'Level 6', target: 'Level 6' },
            { level: 'Level 5', target: 'Level 5' },
            { level: 'Level 4', target: 'Level 4' },
            { level: 'Level 3', target: 'Level 3' },
            { level: 'Level 2', target: 'Level 2' },
            { level: 'Level 1', target: 'Level 1' },
            { level: 'Ground', target: 'Ground'},
            { level: 'Basement', target: 'Basement'},
            //{ level: 'Basement', target: 'Basement', link: { label: 'Car Park Video', url: 'https://www.youtube.com/embed?v=SQrOHlheHHM' } },
        ],   
        'Menu 36': [
            { level: 'Penthouse', target: 'Penthouse' },
            { level: 'Level 6', target: 'Level 6' },
            { level: 'Level 5', target: 'Level 5' },
            { level: 'Level 4', target: 'Level 4' },
            { level: 'Level 3', target: 'Level 3' },
            { level: 'Level 2', target: 'Level 2' },
            { level: 'Level 1', target: 'Level 1' },
            { level: 'Ground', target: 'Ground'},
            { level: 'Basement', target: 'Basement'},
            //{ level: 'Basement', target: 'Basement', link: { label: 'Car Park Video', url: 'https://www.youtube.com/embed?v=SQrOHlheHHM' } },
        ],   
        'Menu 54': [
            { level: 'Penthouse', target: 'Penthouse' },
            { level: 'Level 6', target: 'Level 6' },
            { level: 'Level 5', target: 'Level 5' },
            { level: 'Level 4', target: 'Level 4' },
            { level: 'Level 3', target: 'Level 3' },
            { level: 'Level 2', target: 'Level 2' },
            { level: 'Level 1', target: 'Level 1' },
            { level: 'Ground', target: 'Ground'},
            { level: 'Basement', target: 'Basement'},
            //{ level: 'Basement', target: 'Basement', link: { label: 'Car Park Video', url: 'https://www.youtube.com/embed?v=SQrOHlheHHM' } },
        ],       
    },
    rotationOverlays: {
        0: {
            'Penthouse': { src: './img/residences/3D_Floorplans_Highlights/LEVEL_07_Highlight_Frame_0000.png', top: 0, left: 0 },
            'Level 6': { src: './img/residences/3D_Floorplans_Highlights/LEVEL_06_Highlight_Frame_0000.png', top: 0, left: 0 },
            'Level 5': { src: './img/residences/3D_Floorplans_Highlights/LEVEL_05_Highlight_Frame_0000.png', top: 0, left: 0 },
            'Level 4': { src: './img/residences/3D_Floorplans_Highlights/LEVEL_04_Highlight_Frame_0000.png', top: 0, left: 0 },
            'Level 3': { src: './img/residences/3D_Floorplans_Highlights/LEVEL_03_Highlight_Frame_0000.png', top: 0, left: 0 },
            'Level 2': { src: './img/residences/3D_Floorplans_Highlights/LEVEL_02_Highlight_Frame_0000.png', top: 0, left: 0 },
            'Level 1': { src: './img/residences/3D_Floorplans_Highlights/LEVEL_01_Highlight_Frame_0000.png', top: 0, left: 0 },
        },
        18: {
            'Penthouse': { src: './img/residences/3D_Floorplans_Highlights/LEVEL_07_Highlight_Frame_0018.png', top: 0, left: 0 },
            'Level 6': { src: './img/residences/3D_Floorplans_Highlights/LEVEL_06_Highlight_Frame_0018.png', top: 0, left: 0 },
            'Level 5': { src: './img/residences/3D_Floorplans_Highlights/LEVEL_05_Highlight_Frame_0018.png', top: 0, left: 0 },
            'Level 4': { src: './img/residences/3D_Floorplans_Highlights/LEVEL_04_Highlight_Frame_0018.png', top: 0, left: 0 },
            'Level 3': { src: './img/residences/3D_Floorplans_Highlights/LEVEL_03_Highlight_Frame_0018.png', top: 0, left: 0 },
            'Level 2': { src: './img/residences/3D_Floorplans_Highlights/LEVEL_02_Highlight_Frame_0018.png', top: 0, left: 0 },
            'Level 1': { src: './img/residences/3D_Floorplans_Highlights/LEVEL_01_Highlight_Frame_0018.png', top: 0, left: 0 },
        },
        36: {
            'Penthouse': { src: './img/residences/3D_Floorplans_Highlights/LEVEL_07_Highlight_Frame_0036.png', top: 0, left: 0 },
            'Level 6': { src: './img/residences/3D_Floorplans_Highlights/LEVEL_06_Highlight_Frame_0036.png', top: 0, left: 0 },
            'Level 5': { src: './img/residences/3D_Floorplans_Highlights/LEVEL_05_Highlight_Frame_0036.png', top: 0, left: 0 },
            'Level 4': { src: './img/residences/3D_Floorplans_Highlights/LEVEL_04_Highlight_Frame_0036.png', top: 0, left: 0 },
            'Level 3': { src: './img/residences/3D_Floorplans_Highlights/LEVEL_03_Highlight_Frame_0036.png', top: 0, left: 0 },
            'Level 2': { src: './img/residences/3D_Floorplans_Highlights/LEVEL_02_Highlight_Frame_0036.png', top: 0, left: 0 },
            'Level 1': { src: './img/residences/3D_Floorplans_Highlights/LEVEL_01_Highlight_Frame_0036.png', top: 0, left: 0 },
        },
        54: {
            'Penthouse': { src: './img/residences/3D_Floorplans_Highlights/LEVEL_07_Highlight_Frame_0054.png', top: 0, left: 0 },
            'Level 6': { src: './img/residences/3D_Floorplans_Highlights/LEVEL_06_Highlight_Frame_0054.png', top: 0, left: 0 },
            'Level 5': { src: './img/residences/3D_Floorplans_Highlights/LEVEL_05_Highlight_Frame_0054.png', top: 0, left: 0 },
            'Level 4': { src: './img/residences/3D_Floorplans_Highlights/LEVEL_04_Highlight_Frame_0054.png', top: 0, left: 0 },
            'Level 3': { src: './img/residences/3D_Floorplans_Highlights/LEVEL_03_Highlight_Frame_0054.png', top: 0, left: 0 },
            'Level 2': { src: './img/residences/3D_Floorplans_Highlights/LEVEL_02_Highlight_Frame_0054.png', top: 0, left: 0 },
            'Level 1': { src: './img/residences/3D_Floorplans_Highlights/LEVEL_01_Highlight_Frame_0054.png', top: 0, left: 0 },
        },
    },
    levelGroupApartments: {
        'Ground': [
            { left: '1070px', top: '485px', type: 'photo', align: 'top-left', src: './img/residences/lobby-tmb.jpg', href: './img/gallery-amenities/gallery-amenities-1-lobby.jpg' },
            { left: '1170px', top: '565px', type: 'photo', align: 'bottom-left', src: './img/residences/communal-dining-tmb.jpg', href: './img/gallery-amenities/gallery-amenities-2-communal-dining.jpg'},
            { left: '1320px', top: '765px', type: 'photo', src: './img/residences/pool-tmb.jpg', href: './img/gallery-amenities/gallery-amenities-3-pool.jpg'},
            { left: '1420px', top: '315px', type: 'photo', src: './img/residences/gym-tmb.jpg', href: './img/gallery-amenities/gallery-amenities-4-gym.jpg'},
            { left: '1348px', top: '240px', type: 'photo', align: 'top-right', src: './img/residences/sauna-tmb.jpg', href: './img/gallery-amenities/gallery-amenities-5-sauna.jpg'},
        ],
        'Basement': [
        ],
        'Level 1': [
            { left: '1220px', top: '850px', type: 'photo', align: 'bottom-right', src: './img/residences/balcony-tmb.jpg', href: './img/gallery-building/gallery-building-6-typical-balcony.jpg' },
            { left: '1208px', top: '650px', type: 'photo', align: 'top-right', src: './img/residences/kitchen-tmb.jpg', href: './img/gallery-building/gallery-building-7-typical-kitchen.jpg' },
            { left: '1090px', top: '717px', type: 'photo', align: 'bottom-left', src: './img/residences/dining-tmb.jpg', href: './img/gallery-building/gallery-building-8-typical-dining.jpg' },
            { left: '980px', top: '640px', type: 'photo', align: 'top-left', src: './img/residences/living-tmb.jpg', href: './img/gallery-building/gallery-building-5-typical-living.jpg' },
            { left: '1595px', top: '560px', type: 'photo', align: 'top-right', src: './img/residences/ensuite-tmb.jpg', href: './img/gallery-building/gallery-building-9-typical-ensuite.jpg' },          
            { left: '1570px', top: '740px', type: 'photo', src: './img/residences/master-bedroom-tmb.jpg', href: './img/gallery-building/gallery-building-11-typical-bedroom.jpg' },
            { left: '1440px', top: '5500px', type: 'photo', src: './img/residences/wir-tmb.jpg', href: './img/gallery-building/gallery-building-12-typical-WIR.jpg' }, 
        ],
        'Level 2': [
            { left: '1220px', top: '850px', type: 'photo', align: 'bottom-right', src: './img/residences/balcony-tmb.jpg', href: './img/gallery-building/gallery-building-6-typical-balcony.jpg' },
            { left: '1208px', top: '650px', type: 'photo', align: 'top-right', src: './img/residences/kitchen-tmb.jpg', href: './img/gallery-building/gallery-building-7-typical-kitchen.jpg' },
            { left: '1090px', top: '717px', type: 'photo', align: 'bottom-left', src: './img/residences/dining-tmb.jpg', href: './img/gallery-building/gallery-building-8-typical-dining.jpg' },
            { left: '980px', top: '640px', type: 'photo', align: 'top-left', src: './img/residences/living-tmb.jpg', href: './img/gallery-building/gallery-building-5-typical-living.jpg' },
            { left: '1595px', top: '560px', type: 'photo', align: 'top-right', src: './img/residences/ensuite-tmb.jpg', href: './img/gallery-building/gallery-building-9-typical-ensuite.jpg' },          
            { left: '1570px', top: '740px', type: 'photo', src: './img/residences/master-bedroom-tmb.jpg', href: './img/gallery-building/gallery-building-11-typical-bedroom.jpg' },
            { left: '1440px', top: '5500px', type: 'photo', src: './img/residences/wir-tmb.jpg', href: './img/gallery-building/gallery-building-12-typical-WIR.jpg' }, 
        ],
        'Level 3': [
            { left: '1220px', top: '850px', type: 'photo', align: 'bottom-right', src: './img/residences/balcony-tmb.jpg', href: './img/gallery-building/gallery-building-6-typical-balcony.jpg' },
            { left: '1208px', top: '650px', type: 'photo', align: 'top-right', src: './img/residences/kitchen-tmb.jpg', href: './img/gallery-building/gallery-building-7-typical-kitchen.jpg' },
            { left: '1090px', top: '717px', type: 'photo', align: 'bottom-left', src: './img/residences/dining-tmb.jpg', href: './img/gallery-building/gallery-building-8-typical-dining.jpg' },
            { left: '980px', top: '640px', type: 'photo', align: 'top-left', src: './img/residences/living-tmb.jpg', href: './img/gallery-building/gallery-building-5-typical-living.jpg' },
            { left: '1595px', top: '560px', type: 'photo', align: 'top-right', src: './img/residences/ensuite-tmb.jpg', href: './img/gallery-building/gallery-building-9-typical-ensuite.jpg' },          
            { left: '1570px', top: '740px', type: 'photo', src: './img/residences/master-bedroom-tmb.jpg', href: './img/gallery-building/gallery-building-11-typical-bedroom.jpg' },
            { left: '1440px', top: '5500px', type: 'photo', src: './img/residences/wir-tmb.jpg', href: './img/gallery-building/gallery-building-12-typical-WIR.jpg' }, 
        ],
        'Level 4': [
            { left: '1220px', top: '850px', type: 'photo', align: 'bottom-right', src: './img/residences/balcony-tmb.jpg', href: './img/gallery-building/gallery-building-6-typical-balcony.jpg' },
            { left: '1208px', top: '650px', type: 'photo', align: 'top-right', src: './img/residences/kitchen-tmb.jpg', href: './img/gallery-building/gallery-building-7-typical-kitchen.jpg' },
            { left: '1090px', top: '717px', type: 'photo', align: 'bottom-left', src: './img/residences/dining-tmb.jpg', href: './img/gallery-building/gallery-building-8-typical-dining.jpg' },
            { left: '980px', top: '640px', type: 'photo', align: 'top-left', src: './img/residences/living-tmb.jpg', href: './img/gallery-building/gallery-building-5-typical-living.jpg' },
            { left: '1595px', top: '560px', type: 'photo', align: 'top-right', src: './img/residences/ensuite-tmb.jpg', href: './img/gallery-building/gallery-building-9-typical-ensuite.jpg' },          
            { left: '1570px', top: '740px', type: 'photo', src: './img/residences/master-bedroom-tmb.jpg', href: './img/gallery-building/gallery-building-11-typical-bedroom.jpg' },
            { left: '1440px', top: '5500px', type: 'photo', src: './img/residences/wir-tmb.jpg', href: './img/gallery-building/gallery-building-12-typical-WIR.jpg' }, 
        ],
        'Level 5': [
            { left: '1220px', top: '850px', type: 'photo', align: 'bottom-right', src: './img/residences/balcony-tmb.jpg', href: './img/gallery-building/gallery-building-6-typical-balcony.jpg' },
            { left: '1208px', top: '650px', type: 'photo', align: 'top-right', src: './img/residences/kitchen-tmb.jpg', href: './img/gallery-building/gallery-building-7-typical-kitchen.jpg' },
            { left: '1090px', top: '717px', type: 'photo', align: 'bottom-left', src: './img/residences/dining-tmb.jpg', href: './img/gallery-building/gallery-building-8-typical-dining.jpg' },
            { left: '980px', top: '640px', type: 'photo', align: 'top-left', src: './img/residences/living-tmb.jpg', href: './img/gallery-building/gallery-building-5-typical-living.jpg' },
            { left: '1595px', top: '560px', type: 'photo', align: 'top-right', src: './img/residences/ensuite-tmb.jpg', href: './img/gallery-building/gallery-building-9-typical-ensuite.jpg' },          
            { left: '1570px', top: '740px', type: 'photo', src: './img/residences/master-bedroom-tmb.jpg', href: './img/gallery-building/gallery-building-11-typical-bedroom.jpg' },
            { left: '1440px', top: '5500px', type: 'photo', src: './img/residences/wir-tmb.jpg', href: './img/gallery-building/gallery-building-12-typical-WIR.jpg' }, 
        ],
        'Level 6': [
            { left: '1220px', top: '850px', type: 'photo', align: 'bottom-right', src: './img/residences/balcony-tmb.jpg', href: './img/gallery-building/gallery-building-6-typical-balcony.jpg' },
            { left: '1208px', top: '650px', type: 'photo', align: 'top-right', src: './img/residences/kitchen-tmb.jpg', href: './img/gallery-building/gallery-building-7-typical-kitchen.jpg' },
            { left: '1090px', top: '717px', type: 'photo', align: 'bottom-left', src: './img/residences/dining-tmb.jpg', href: './img/gallery-building/gallery-building-8-typical-dining.jpg' },
            { left: '980px', top: '640px', type: 'photo', align: 'top-left', src: './img/residences/living-tmb.jpg', href: './img/gallery-building/gallery-building-5-typical-living.jpg' },
            { left: '1595px', top: '560px', type: 'photo', align: 'top-right', src: './img/residences/ensuite-tmb.jpg', href: './img/gallery-building/gallery-building-9-typical-ensuite.jpg' },          
            { left: '1570px', top: '740px', type: 'photo', src: './img/residences/master-bedroom-tmb.jpg', href: './img/gallery-building/gallery-building-11-typical-bedroom.jpg' },
            { left: '1440px', top: '5500px', type: 'photo', src: './img/residences/wir-tmb.jpg', href: './img/gallery-building/gallery-building-12-typical-WIR.jpg' }, 
        ],
        'Penthouse': [
            { left: '1220px', top: '850px', type: 'photo', align: 'bottom-right', src: './img/residences/balcony-tmb.jpg', href: './img/gallery-building/gallery-building-6-typical-balcony.jpg' },
            { left: '1208px', top: '650px', type: 'photo', align: 'top-right', src: './img/residences/kitchen-tmb.jpg', href: './img/gallery-building/gallery-building-7-typical-kitchen.jpg' },
            { left: '1090px', top: '717px', type: 'photo', align: 'bottom-left', src: './img/residences/dining-tmb.jpg', href: './img/gallery-building/gallery-building-8-typical-dining.jpg' },
            { left: '980px', top: '640px', type: 'photo', align: 'top-left', src: './img/residences/living-tmb.jpg', href: './img/gallery-building/gallery-building-5-typical-living.jpg' },
            { left: '1595px', top: '560px', type: 'photo', align: 'top-right', src: './img/residences/ensuite-tmb.jpg', href: './img/gallery-building/gallery-building-9-typical-ensuite.jpg' },          
            { left: '1570px', top: '740px', type: 'photo', src: './img/residences/master-bedroom-tmb.jpg', href: './img/gallery-building/gallery-building-11-typical-bedroom.jpg' },
            { left: '1440px', top: '5500px', type: 'photo', src: './img/residences/wir-tmb.jpg', href: './img/gallery-building/gallery-building-12-typical-WIR.jpg' }, 
        ],
    },

}
