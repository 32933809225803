import produce from 'immer';


// Actions
const SELECT_YEAR = 'QWR_APP/MAJOR_PROJECTS/SELECT_YEAR';


// Reducer
export default function reducer (state = { currYear: 0 }, action = {}) {
    switch (action.type) {
        case SELECT_YEAR :
            return produce(state, draftState => {
                draftState.currYear = action.currYear;
              });       
        default :
            return state;
      }
}


// Action Creators
export const selectYear = (currYear) => ( {
    type: SELECT_YEAR,
    currYear
} );
