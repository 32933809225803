import React, { useEffect, useState } from "react";

const ButtonHotSpotWithNumber = ({ data, selectApartment }) => {
    // const [number, setNumber] = useState({
    //     class: 'left',
    //     orientation: 'left',
    //     label: '1',
    // })

    // useEffect(() => {
    //     setNumber(data.number);
    // }, []);

    // const renderNumber = () => {
    //     const number = data.number;

    //     if (number.orientation === 'left') {
    //         return <span className="icon-number" style={ { left: (parseInt(data.left.replace(/px/,""))-50)+"px", top: (parseInt(data.top.replace(/px/,""))+5)+"px" } }>{number.label}</span>
    //     }
    //     if (number.orientation === 'right') {
    //         return <span className="icon-number" style={ { left: (parseInt(data.left.replace(/px/,""))+55)+"px", top: (parseInt(data.top.replace(/px/,""))+5)+"px" } }>{number.label}</span>
    //     }
    //     if (number.orientation === 'top') {
    //         return <span className="icon-number" style={ { left: (parseInt(data.left.replace(/px/,""))+5)+"px", top: (parseInt(data.top.replace(/px/,""))-45)+"px" } }>{number.label}</span>
    //     }
    //     if (number.orientation === 'bottom') {
    //         return <span className="icon-number" style={ { left: (parseInt(data.left.replace(/px/,""))+5)+"px", top: (parseInt(data.top.replace(/px/,""))+55)+"px" } }>{number.label}</span>
    //     }
    // }
    return (
        <>
            <button className='residences--apartment-btn' style={ { left: data.left, top: data.top } } onClick={ () => selectApartment(data.title) }></button>
        </>
    );
}

export default ButtonHotSpotWithNumber;