import React, { useEffect, useRef, useState } from "react";
import { utilAnimate } from "../../util/utilAnimate";
import SideMenu from "./sideMenu/SideMenu";
import View3dAPP from "./ViewTour_H3SA";

const ViewVirtualTour = () => {
    const containerRef = useRef(null);
    const [iFrame, setIframe] = useState('/vr-app/east/index.htm');
    useEffect(() => {
        utilAnimate.fadeIn(containerRef.current);
    }, []);

    const handleUrlChange = (url) => {
        setIframe(url);
    };

    return (
            <div ref={containerRef} className="tour--rotations">
                <View3dAPP url={iFrame} />
                <SideMenu onUrlChange={(url) => handleUrlChange(url)} />
            </div>
    )
}

export default ViewVirtualTour;