
import React, { useEffect, useState } from 'react';
import { NavLink, useRouteMatch, useLocation } from 'react-router-dom';
import ApartmentDropDownSubMenu from './ApartmentDropDownSubMenu';
import './stylesDropDown.scss';
import ButtonHotSpotWithNumber from './ButtonHotSpotWithNumber';


export default function ApartmentDropDown ({ data, selectApartment }) {   
    
    const getLinks = () => {
        const links = [];
        
        
        if (Array.isArray(data.title)) {
            links.push(
                <ApartmentDropDownSubMenu options={ data.title } data={data} />
            );
        } else {
            links.push(
                <ButtonHotSpotWithNumber data={data} selectApartment={selectApartment} />
            );
        }
        return links;
    }

    return (
        getLinks()
    );

}