import produce from 'immer';


// Actions
const SELECT_PRECINCT = 'QWR_APP/PRECINCTS/SELECT_PRECINCT';

// Reducer
export default function reducer (state = { currPrecinct: '' }, action = {}) {
    switch (action.type) {
        case SELECT_PRECINCT :
            return produce(state, draftState => {
                draftState.currPrecinct = action.currPrecinct;
              });       
        default :
            return state;
    }
}


// Action Creators
export const selectPrecinct = (currPrecinct) => ( {
    type: SELECT_PRECINCT,
    currPrecinct
} );
